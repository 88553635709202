body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.custom-scrollbar::-webkit-scrollbar {
	width: 5px;
}

.custom-scrollbar::-webkit-scrollbar-track {
	background: #e5e7eb;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
	background: #d1d5db;
	border-radius: 8px;
	background-clip: padding-box;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
	background: #9ca3af;
}

/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}

.custom-scrollbar {
	scrollbar-width: thin;
}

.break-word {
	word-break: break-word;
}

.connectors-table .table-row-group .table-row:first-child .table-cell {
	border-style: dashed;
}

.dropdown-shadow-effect {
	box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);
}

@import './datepicker.css';
