.react-datepicker__navigation-icon::before {
	top: 12px;
}

.react-datepicker__current-month {
	margin-bottom: 5px;
}

.react-datepicker__header {
	background-color: #f3f4f6;
}

.react-datepicker__month-select, .react-datepicker__year-select {
	border-color: #d1d5db;
	border-radius: 6px;
	font-size: 14px;
	padding-right: 3rem;
}

.react-datepicker__month-select:focus, .react-datepicker__year-select:focus {
	border-color: #2563eb;
	box-shadow: none;
}

.react-datepicker__day--selected {
	background-color: #2563eb;
}

.react-datepicker__day--selected:hover {
	background-color: #1d4ed8;
}

.react-datepicker-wrapper {
	width: 100%;
}
