@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .heading-4xl {
    @apply text-4xl font-extrabold text-gray-800;
  }

  .heading-3xl {
    @apply text-3xl font-extrabold text-gray-900;
  }

  .heading-2xl {
    @apply text-2xl font-extrabold text-gray-900;
  }

  .heading-xl {
    @apply text-xl font-bold text-gray-800;
  }

  .heading-lg {
    @apply text-lg leading-6 font-medium text-gray-900;
  }

  .text-secondary {
    @apply text-sm text-gray-500;
  }
}

@layer utilities {
  .progress-gradient {
    background: linear-gradient(180deg, #4b83ee 0%, #3266e2 100%);
  }
}

@layer base {
  :root {
    --color-text-base: #2563eb;
    --color-button-primary: #2563eb;
    --color-button-primary-hover: #3b82f6;
    --color-button-blue-primary: #2563eb;
    --color-button-blue-primary-hover: #3b82f6;
    --color-progress-fill: #2563eb;
    --color-bage-primary: #dbeafe;
  }
  .theme-paya {
    --color-button-primary: #6cc24a;
    --color-button-primary-hover: #5cb03b;
    --color-button-blue-primary: #00a9e0;
    --color-button-blue-primary-hover: #0083bd;
    --color-bage-primary: #e0f2fe;
    --color-progress-fill: #00a9e0;
    --color-text-base-hover: #0083bd;
    --color-text-base: #00a9e0;
    --color-text-secondery: #0369a1;
  }

	@supports (font-variation-settings: normal) {
		@font-face {
			font-family: 'Inter';
			font-style: normal;
			font-weight: 100 900;
			font-display: swap;
			src: url('assets/fonts/Inter-roman.var.woff2') format('woff2');
			font-named-instance: 'Regular';
		}
	
		@font-face {
			font-family: 'Inter';
			font-style: italic;
			font-weight: 100 900;
			font-display: swap;
			src: url('assets/fonts/Inter-italic.var.woff2') format('woff2');
			font-named-instance: 'Italic';
		}
	}
	
	@supports not (font-variation-settings: normal) {
		@font-face {
			font-named-instance: 'Thin';
			font-family: 'Inter';
			font-style: normal;
			font-weight: 100;
			font-display: swap;
			src: url('assets/fonts/Inter-Thin.woff2') format('woff2');
		}
	
		@font-face {
			font-named-instance: 'ThinItalic';
			font-family: 'Inter';
			font-style: italic;
			font-weight: 100;
			font-display: swap;
			src: url('assets/fonts/Inter-ThinItalic.woff2') format('woff2');
		}
	
		@font-face {
			font-named-instance: 'ExtraLight';
			font-family: 'Inter';
			font-style: normal;
			font-weight: 200;
			font-display: swap;
			src: url('assets/fonts/Inter-ExtraLight.woff2') format('woff2');
		}
	
		@font-face {
			font-named-instance: 'ExtraLightItalic';
			font-family: 'Inter';
			font-style: italic;
			font-weight: 200;
			font-display: swap;
			src: url('assets/fonts/Inter-ExtraLightItalic.woff2') format('woff2');
		}
	
		@font-face {
			font-named-instance: 'Light';
			font-family: 'Inter';
			font-style: normal;
			font-weight: 300;
			font-display: swap;
			src: url('assets/fonts/Inter-Light.woff2') format('woff2');
		}
	
		@font-face {
			font-named-instance: 'LightItalic';
			font-family: 'Inter';
			font-style: italic;
			font-weight: 300;
			font-display: swap;
			src: url('assets/fonts/Inter-LightItalic.woff2') format('woff2');
		}
	
		@font-face {
			font-named-instance: 'Regular';
			font-family: 'Inter';
			font-style: normal;
			font-weight: 400;
			font-display: swap;
			src: url('assets/fonts/Inter-Regular.woff2') format('woff2');
		}
		
		@font-face {
			font-named-instance: 'Italic';
			font-family: 'Inter';
			font-style: italic;
			font-weight: 400;
			font-display: swap;
			src: url('assets/fonts/Inter-Italic.woff2') format('woff2');
		}
	
		@font-face {
			font-named-instance: 'Medium';
			font-family: 'Inter';
			font-style: normal;
			font-weight: 500;
			font-display: swap;
			src: url('assets/fonts/Inter-Medium.woff2') format('woff2');
		}
	
		@font-face {
			font-named-instance: 'MediumItalic';
			font-family: 'Inter';
			font-style: italic;
			font-weight: 500;
			font-display: swap;
			src: url('assets/fonts/Inter-MediumItalic.woff2') format('woff2');
		}
	
		@font-face {
			font-named-instance: 'SemiBold';
			font-family: 'Inter';
			font-style: normal;
			font-weight: 600;
			font-display: swap;
			src: url('assets/fonts/Inter-SemiBold.woff2') format('woff2');
		}
	
		@font-face {
			font-named-instance: 'SemiBoldItalic';
			font-family: 'Inter';
			font-style: italic;
			font-weight: 600;
			font-display: swap;
			src: url('assets/fonts/Inter-SemiBoldItalic.woff2') format('woff2');
		}
	
		@font-face {
			font-named-instance: 'Bold';
			font-family: 'Inter';
			font-style: normal;
			font-weight: 700;
			font-display: swap;
			src: url('assets/fonts/Inter-Bold.woff2') format('woff2');
		}
	
		@font-face {
			font-named-instance: 'BoldItalic';
			font-family: 'Inter';
			font-style: italic;
			font-weight: 700;
			font-display: swap;
			src: url('assets/fonts/Inter-BoldItalic.woff2') format('woff2');
		}
	
		@font-face {
			font-named-instance: 'ExtraBold';
			font-family: 'Inter';
			font-style: normal;
			font-weight: 800;
			font-display: swap;
			src: url('assets/fonts/Inter-ExtraBold.woff2') format('woff2');
		}
	
		@font-face {
			font-named-instance: 'ExtraBoldItalic';
			font-family: 'Inter';
			font-style: italic;
			font-weight: 800;
			font-display: swap;
			src: url('assets/fonts/Inter-ExtraBoldItalic.woff2') format('woff2');
		}
	
		@font-face {
			font-named-instance: 'Black';
			font-family: 'Inter';
			font-style: normal;
			font-weight: 900;
			font-display: swap;
			src: url('assets/fonts/Inter-Black.woff2') format('woff2');
		}
	
		@font-face {
			font-named-instance: 'BlackItalic';
			font-family: 'Inter';
			font-style: italic;
			font-weight: 900;
			font-display: swap;
			src: url('assets/fonts/Inter-BlackItalic.woff2') format('woff2');
		}
	}
}
